(function () {
    angular.module('informaApp')
        .controller('NewFeatureViewController', NewFeatureView);

    function NewFeatureView($scope, NewFeatureFileTypes, NavigatorService) {
        $scope.fileTypes = NewFeatureFileTypes;

        $scope.init = () => {
            if ((!$scope.$resolve.data.featureList || !$scope.$resolve.data.featureList.length) && !$scope.$resolve.data.feature) {
                NavigatorService.openTable();
            }

            $scope.featureChunks = $scope.$resolve.data.featureList && _.chunk($scope.$resolve.data.featureList, 2);
        };

        $scope.onFeatureCardOpenLinkClick = (feature) => {
            NavigatorService.openFeatureCard(feature.id);
        }
    }
})();
